(function(){
'use strict';

scSupporterDashboardHeaderDirective.$inject = ["$state", "$timeout", "$window", "scBlocksService", "scFlowModalService", "scThemesService", "scPagesService", "scSocialShareLinks", "scProgressDataFactory"];
angular.module('classy.frs').directive('scSupporterDashboardHeader', scSupporterDashboardHeaderDirective);

function scSupporterDashboardHeaderDirective($state, $timeout, $window, scBlocksService, scFlowModalService, scThemesService, scPagesService, scSocialShareLinks, scProgressDataFactory) {
  return {
    templateUrl: 'frs/shared/supporter/header/template',
    scope: true,
    controller: ["$scope", function controller($scope) {
      $scope.headerBlock = scBlocksService.getBlockByType('header');
    }],
    link: function link(scope) {
      if ($state.includes('frs.fundraiser')) {
        scope.pageBlock = scBlocksService.getBlockByType('fundraiser');
      } else {
        scope.pageBlock = scBlocksService.getBlockByType('team');
      }

      scope.theme = scThemesService.active;

      scope.winWidth = 0;

      scope.shareUrl = scPagesService.getShareableUrl();

      scope.progressData = scProgressDataFactory.getEntityProgress(scope.currentPage);

      scope.showCommitment = !!scope.progressData.commitment;
      if (scope.showCommitment) {
        scope.commitmentLeftToRaise = scope.progressData.commitment.value - scope.progressData.totalRaised;
      }

      if (scope.active && scope.active.registerObserverCallback) {
        scope.active.registerObserverCallback(function (currentPage) {
          scope.progressData = scProgressDataFactory.getEntityProgress(currentPage);
        });
      }

      scSocialShareLinks.getSocialData().then(function (socialData) {
        scope.twitterShareUrl = socialData.twitter_link;
        scope.fbShare = socialData.facebook_share;
      });

      scope.viewPageLink = function () {
        if ($state.params.fundraisingTeamId) {
          return '/team/' + $state.params.fundraisingTeamId;
        } else if ($state.params.fundraisingPageId) {
          return '/fundraiser/' + $state.params.fundraisingPageId;
        }

        return '';
      };

      scope.link = function () {
        scFlowModalService.open({
          templateUrl: 'frs/shared/supporter/header/url-copy',
          context: scope,
          maxWidth: 400
        });
      };

      // On mobile, only show header on overview tab
      scope.displayHeader = function () {
        return scope.winWidth <= 800 ? $state.current.url === '/overview' : true;
      };

      /* -------------------------------------------------------------------- *
       * Utils
       * -------------------------------------------------------------------- */
      function setWindowWidth() {
        $timeout(function () {
          scope.winWidth = angular.element($window).width();
        });
      }

      /* -------------------------------------------------------------------- *
       * Watches
       * -------------------------------------------------------------------- */
      angular.element($window).on('resize orientationchange', setWindowWidth);
      scope.$on('$destroy', function () {
        angular.element($window).off('resize orientationchange', setWindowWidth);
      });

      /* -------------------------------------------------------------------- *
       * Init
       * -------------------------------------------------------------------- */
      setWindowWidth();
    }
  };
}
})();