(function(){
'use strict';

angular.module('classy.frs.donation').controller('frsDonationEmbeddedGivingCtrl', ["$stateParams", "$state", "$scope", "EmbeddedGiving", "scCampaignsService", function ($stateParams, $state, $scope, EmbeddedGiving, scCampaignsService) {
  if (EmbeddedGiving.hasEmbeddedGiving(scCampaignsService.active)) {
    EmbeddedGiving.init({
      isEnabled: !!$stateParams.eg,
      param: $stateParams.egp
    });
    $scope.bodySection = _.find($scope.page.block_sections, { name: 'body' });
  } else {
    $state.go('frs.donation.checkout', { eg: null, egp: null, egfa: null, egrn: null });
  }
}]);
})();