(function(){
'use strict';

angular.module('classy.frs.donation').controller('frs-donationCtrl', ["$scope", "scAnalytics", "scCampaignsService", "scCurrencyService", "scFundraisingTeamsService", "scFundraisingPagesService", "CryptoGiving", function ($scope, scAnalytics, scCampaignsService, scCurrencyService, scFundraisingTeamsService, scFundraisingPagesService, CryptoGiving) {
  if ($scope.previewMode) {
    $scope.team = scFundraisingTeamsService.useExample();
    $scope.fundraiser = scFundraisingPagesService.useExample();
  } else {
    $scope.team = scFundraisingTeamsService.active;
    $scope.fundraiser = scFundraisingPagesService.active;
  }

  /* Init Crypto Giving (iff has access & active crypto processor) */
  if (CryptoGiving.hasAccess() && CryptoGiving.hasProcessor()) {
    CryptoGiving.init({
      // $scope.campaign not used here because not instance of scCampaignModel (why isnt it?)
      isEnabled: scCampaignsService.active.hasCryptoGiving(),
      isExclusive: scCampaignsService.active.hasOnlyCryptoGiving(),
      isHidden: !scCampaignsService.active.hasOnlyCryptoGiving() // If not exclusive, then default to normal experience first
    });
  }

  // Begin Checkout Event
  var currency = scCurrencyService.getEntityCampaignCurrency(scCampaignsService.active.current);

  scAnalytics.eventBeacon({
    label: 'Begin Checkout',
    action: 'checkout',
    currency: currency,
    items: [{
      item_id: scCampaignsService.active.current.id,
      item_name: scCampaignsService.active.current.name,
      item_category: scCampaignsService.active.current.type,
      currency: currency
    }]
  });
}]);
})();