(function(){
'use strict';

FundraisingTeamDetailsCtrl.$inject = ["$scope", "$log", "$q", "$http", "scBlocksService", "scPagesService", "scMembersService", "scFundraisingTeamsService", "scCampaignsService", "scOrganizationsService", "scImg", "scFroalaOptions", "scBanner", "scDomainsService", "scAppealSetService"];
angular.module('classy.frs').controller('fundraisingTeamDetailsCtrl', FundraisingTeamDetailsCtrl);

function FundraisingTeamDetailsCtrl($scope, $log, $q, $http, scBlocksService, scPagesService, scMembersService, scFundraisingTeamsService, scCampaignsService, scOrganizationsService, scImg, scFroalaOptions, scBanner, scDomainsService, scAppealSetService) {
  var preferences = void 0;
  var oldSlugObj = void 0;
  var hadSlug = false;

  $scope.block = scBlocksService.getBlockByType('team');
  $scope.isPageOwner = $scope.team.current.team_lead_id == scMembersService.active.current.id;

  $scope.showAllocations = false;
  $scope.canSetAppeal = _.get(scCampaignsService, 'active.current.teams_can_set_appeal', true);

  $scope.formSubmitting = false;
  $scope.froalaOptions = scFroalaOptions({ type: 'bare-headline-max-length' }, {
    editorClass: 'dashboard-details bordered'
  });

  // fixme: this should actually pull from the campaign when it is a property available on it.
  $scope.orgAllowsSubteams = scOrganizationsService.active.current.tags.some(function (tag) {
    return tag === 'allow-subteams';
  });

  scDomainsService.getSlug('fundraising-teams', scFundraisingTeamsService.active.current.id).then(function (response) {
    if (response.data.total > 0) {
      $scope.team.current.domainSlug = response.data.data[0].value;
      oldSlugObj = response.data.data[0];
      hadSlug = true;
    } else {
      $scope.team.current.domainSlug = '';
      oldSlugObj = null;
      hadSlug = false;
    }
  });

  $scope.removeButtonAction = function () {
    $scope.team.current.logo = '';
    $scope.team.current.logo_id = null;
  };

  $scope.resetHeadlineText = 'Reset team headline';

  $scope.resetTeamHeadline = function () {
    $scope.team.current.description = scCampaignsService.active.current.default_team_appeal || '';
  };

  $scope.disableCurrencyDropdown = function () {
    return scFundraisingTeamsService.active.current.transactions_count > 0 || scFundraisingTeamsService.active.current.soft_credits_count > 0 || scFundraisingTeamsService.active.current.percent_to_goal > 0;
  };

  $scope.subscribeOptions = {
    comments: {
      key: 'team.comment',
      subscribe: true
    },
    donations: {
      key: 'team.donation',
      subscribe: true
    },
    encouragement: {
      key: 'team.series',
      subscribe: true
    },
    management: {
      key: 'team.team',
      subscribe: true
    }
  };

  if ($scope.isPageOwner) {
    $http.get('/frs-api/campaigns/' + $scope.team.current.campaign_id + '/notification-preferences').then(function (response) {
      preferences = response.data;

      _.forEach($scope.subscribeOptions, function (opt) {
        if (preferences.unsubscribe_tags.indexOf(opt.key) > -1) {
          opt.subscribe = false;
        }
      });
    }).catch(function (error) {
      $log.error(error);
      scBanner('There was an error loading your notification preferences.');
    });
  }

  $scope.$watch('team.current.logo', function (value) {
    if (!value) {
      if ($scope.block.current.defaultTeamLogo) {
        $scope.team.current.logo = $scope.block.current.defaultTeamLogo;
        $scope.team.current.logo_id = $scope.block.current.defaultTeamLogo.asset.assetId;
      } else {
        $scope.team.current.logo = null;
      }

      $scope.hideRemoveButton = true;
    } else if (scImg.isInstance(value)) {
      $scope.team.current.logo_id = value.asset.assetId;
      $scope.hideRemoveButton = $scope.team.current.logo.is($scope.block.current.defaultTeamLogo);
    }
  });

  $scope.allocationModels = [{
    value: 'manual',
    label: 'Manually split incoming team donations'
  }, {
    value: 'automatic-even-split',
    label: 'Auto split incoming team donations'
  }];

  $scope.membershipModels = {
    'private-confirmation-required': 'Private - Captain approves new teammates',
    'private-invite-only': 'Private - Invite only',
    'private-password': 'Private - Password required',
    public: 'Public - Anyone can join'
  };

  $scope.teamTypes = {
    corporate: 'Corporate'
  };

  $scope.formSubmitText = function () {
    return 'Save Changes';
  };

  $scope.submit = function () {
    $scope.formSubmitting = true;

    if (!oldSlugObj || oldSlugObj.value != $scope.team.current.domainSlug) {
      scDomainsService.saveChanges(oldSlugObj, $scope.team.current.domainSlug, hadSlug, 'fundraising-teams', scFundraisingTeamsService.active.current.id).then(function (response) {
        oldSlugObj = response.data;
      });
    }

    var notificationsUpdate = void 0;
    if ($scope.isPageOwner) {
      var unsubscribeTags = _(preferences.unsubscribe_tags);
      var subscribed = [];
      var unsubscribed = [];
      _.forEach($scope.subscribeOptions, function (opt) {
        if (opt.subscribe) {
          subscribed.push(opt.key);
        } else {
          unsubscribed.push(opt.key);
        }
      });

      unsubscribeTags = unsubscribeTags.difference(subscribed).union(unsubscribed).value();
      preferences.unsubscribe_tags = unsubscribeTags;
      notificationsUpdate = $http.post('/frs-api/campaigns/' + $scope.team.current.campaign_id + '/notification-preferences', preferences);
    } else {
      notificationsUpdate = $q.resolve();
    }

    var fundraisingTeamUpdate = $scope.team.updateGroup();

    $q.all([fundraisingTeamUpdate, notificationsUpdate]).then(function (responses) {
      scBanner('Your team has been edited!', 'success');
      $scope.currentPage.data = responses[0].data;
      $scope.currentPage.name = responses[0].data.name;
      scAppealSetService.rescrape('team');
    }).catch(function (error) {
      $log.error(error);
      scBanner('An error occured while updating your details. Please try again.', 'error');
    }).finally(function () {
      $scope.formSubmitting = false;
    });
  };

  scPagesService.decorate($scope, 'supporter', 'details-fundraising-team');
}
})();